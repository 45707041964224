import { ref, reactive, onMounted,onUnmounted} from 'vue';
// import {useStore} from 'vuex';
import store from '@/store/';
import {Stage, Layer, Group, Text} from 'konva';


const Simpzoom = function(radio,maxWidth,maxHeight){
    // console.log(radio,maxWidth,maxHeight)
    if(maxWidth/maxHeight>=radio&& maxHeight*radio>=1000){
        return [maxHeight*radio, maxHeight]
    }
    if(maxWidth/maxHeight<radio&& maxWidth>=1000){
        return [maxWidth, maxWidth/radio]
    }

    return [];
}


//展位显示页面，设置展位外框，内框，canvas尺寸
export function useGetMapStyle(selectCategory) {
    //外边距为背景图片缩放至1500像素时候，外边度的实际像素点
    let {width,height,left_margin,right_margin,top_margin,bottom_margin,picture} = selectCategory;
    console.log(width,height,left_margin,right_margin,top_margin,bottom_margin);
    let outerWidth = 1500;
    let outerHeight = (outerWidth-left_margin-right_margin)/width*height+top_margin+bottom_margin;
    const windowWidth = document.documentElement.clientWidth-100; //左右边距共100
    const windowHeight = document.documentElement.clientHeight-80-60-44-40; //上下边距共40
    // const windowWidth = 1920-60; //左右边距共100
    // const windowHeight = 1080-40; //上下边距共40

    // 根据浏览器窗口大小，调整视口大小
    const newSize = Simpzoom(outerWidth/outerHeight,windowWidth,windowHeight);
    // console.log(newSize);
    if(newSize.length===2){
        outerWidth = newSize[0];
        outerHeight = newSize[1];
    }

    //背景图边距是把图片缩放到1500px下测量出来的，因此在动态改变画布尺寸，需要等比例缩放边距
    // 边距缩放系数
    const marginRadio = outerWidth/1500;
    left_margin = 0;
    right_margin = 0;
    top_margin = 0;
    bottom_margin = 0;


    let border="1px solid #fff"
	//没有背景图，显示边框
	if(selectCategory.picture==='http://saleadmin.uniceramics.com.cn/storage/showroom_category/'){
	    border="1px solid #333"
	}

	return {
			outer:{
                // backgroundImage: 'url('+ picture +')',
                // backgroundSize:'100% 100%',
                width:outerWidth+'px',
                height:outerHeight+'px',
                // border:border
			},
			inner:{
                width: (outerWidth-left_margin-right_margin)+'px',
                height: (outerWidth-left_margin-right_margin)/width*height+'px',
                // top: top_margin+'px',
                // left: left_margin+'px'
		    },
            // canvasWidth:(outerWidth-left_margin-right_margin)-2,
            // canvasHeight:(outerWidth-left_margin-right_margin)/width*height-2
            canvasWidth:(1860-left_margin-right_margin)-2,
            canvasHeight:(1860-left_margin-right_margin)/width*height-2
    }
}

//展位编辑页面，设置展位外框，内框，canvas尺寸
export function useGetMapStyle2(selectCategory) {
    //外边距为背景图片缩放至1500像素时候，外边度的实际像素点
    let {width,height,left_margin,right_margin,top_margin,bottom_margin,picture} = selectCategory;
    console.log(width,height,left_margin,right_margin,top_margin,bottom_margin);
    let outerWidth = 1500;
    let outerHeight = outerWidth/width*height;
    // const windowWidth = document.documentElement.clientWidth-60; //左右边距共100
    // const windowHeight = document.documentElement.clientHeight-100-40; //上下边距共40
    const windowWidth = 1920-60; //左右边距共100
    const windowHeight = 1080-40; //上下边距共40

    // 根据浏览器窗口大小，调整视口大小
    const newSize = Simpzoom(outerWidth/outerHeight,windowWidth,windowHeight);
    // console.log(newSize);
    if(newSize.length===2){
        outerWidth = newSize[0];
        outerHeight = newSize[1];
    }


	return {
			outer:{
                width:outerWidth+'px',
                height:outerHeight+'px'
			},
            canvasWidth:outerWidth-2,
            canvasHeight:outerHeight-2
    }
}

// 设置键盘操控
export function usekeyBoard(stage, layer, radio) {
    // const layer = new Layer();
    // const store = useStore();
    const guidWidth = store.state.guidWidth;
    const container = stage.container();
    container.tabIndex = 1;
    container.focus();
    const DELTA = guidWidth*radio;
    


    // container.addEventListener('keydown', function(e) {
    container.onkeydown = function(e){
        console.log(e.keyCode);
        const selectGrout = store.state.selectGroup;
        if(selectGrout===null){
            return false;
        }
        console.log("移动矩形");
        if (e.keyCode === 37 || e.keyCode ===65) {
            (selectGrout.x() - DELTA)>0 && selectGrout.x(selectGrout.x() - DELTA);
        } else if (e.keyCode === 38 || e.keyCode ===87) {
            (selectGrout.y() - DELTA)>0 && selectGrout.y(selectGrout.y() - DELTA);
        } else if (e.keyCode === 39 || e.keyCode === 68) {
            selectGrout.x(selectGrout.x() + DELTA);
        } else if (e.keyCode === 40 || e.keyCode === 83) {
            selectGrout.y(selectGrout.y() + DELTA);
        }else if (e.keyCode === 46) {
            //删除
            //console.log(selectGrout.name())
             //tempGroup 此处需要判断矩形组的类型，临时的可以直接删除，服务器中的需要调用接口删除
            
            selectGrout.remove();
            stage.find('Transformer').destroy();
        } else {
          return;
        }

        //移动矩形后需要更新store中的矩形数据
        



        e.preventDefault();
        layer.batchDraw();
    }
    // });
}


// 拖放对齐到网格
export function useSetDragAlign(elem,radio){
    const guidWidth = store.state.guidWidth;
    const selectCategory = store.state.selectCategory
    let guidWidthPix = radio*guidWidth;
    const {x,y} = elem.attrs;
    const left_offset_pix = selectCategory.left_padding%guidWidth*radio;
    const top_offset_pix = selectCategory.top_padding%guidWidth*radio;
    let new_x, new_y;
    if((x-left_offset_pix)%guidWidthPix > guidWidthPix/2){
        new_x = x-(x-left_offset_pix)%guidWidthPix+guidWidthPix
    }else{
        new_x = x-(x-left_offset_pix)%guidWidthPix
    }
    if((y-top_offset_pix)%guidWidthPix > guidWidthPix/2){
        new_y = y-(y-top_offset_pix)%guidWidthPix+guidWidthPix
    }else{
        new_y = y-(y-top_offset_pix)%guidWidthPix
    }
    elem.setAttrs({
        x: new_x,
        y: new_y
    });
}