import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Group, Text, Line} from 'konva';
import store from '@/store/';

export function drawRuler(rect,width,height,group) {

    const selectCategory = computed(() => store.state.selectCategory);
    const lists = computed(() => store.state.lists);

    const radio = width/selectCategory.value.width;
    let rleft = Math.round(rect.left*radio);
    let rtop = Math.round(rect.top*radio);
    let rheight = Math.round(rect.height*radio);
    let rwidth = Math.round(rect.width*radio);

    let strokeWidth = 0.5;
    let fill = '#666';
    let stroke = '#666';
    let fontSize = 10;
    
    let offset = 800*radio;
    //画左侧标尺
    if(checkCross({left:rleft-offset, top:rtop, width:offset, height: rheight},radio,lists.value)){
        group.add(new Line({
            points: [0-offset,0,0-offset,0+rheight],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [-offset-4, 0, -4, 0],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [-offset-4, rheight, -4, rheight],
            stroke,
            strokeWidth
        }));
        group.add(new Text({
            x:-offset-4, y:0,
            width:rheight,
            text:rect.height,
            fill, fontSize, strokeWidth,
            align:'center',
            rotation:90
        }));
        group.add(new Line({
            points: [-offset-3,-3, -offset+3,3],
            stroke, strokeWidth
        }));
        group.add(new Line({
            points: [-offset-3,rheight-3, -offset+3,rheight+3],
            stroke, strokeWidth
        }));
    }

    //画下面侧标尺 ----上半部分展位
    if(rect.top*radio<height/2 && checkCross({left:rleft, top:rtop+rheight+4, width:rwidth, height: offset},radio,lists.value)){
        group.add(new Line({
            points: [0,rheight+offset, rwidth, rheight+offset],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [0,rheight+5,0, rheight+offset+5],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [rwidth,rheight+5,rwidth, rheight+offset+5],
            stroke,
            strokeWidth
        }));
        group.add(new Text({
            x:0, y:rheight+offset+4,
            width:rwidth,
            text:rect.width,
            fill, fontSize, strokeWidth,
            align:'center',
        }));
        group.add(new Line({
            points: [+3,rheight+offset-3,-3,rheight+offset+3],
            stroke, strokeWidth
        }));
        group.add(new Line({
            points: [+rwidth+3, rheight+offset-3, rwidth-3, rheight+offset+3],
            stroke, strokeWidth
        }));

    }

    //画上面侧标尺 ----下半部分展位
    if(rect.top*radio>height/2 && checkCross({left:rleft, top:rtop-offset-4, width:rwidth, height: offset},radio,lists.value)){
        group.add(new Line({
            points: [0,-offset, rwidth, -offset],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [0,-5, 0, -offset-5],
            stroke,
            strokeWidth
        }));
        group.add(new Line({
            points: [rwidth,-5,rwidth, -offset-5],
            stroke,
            strokeWidth
        }));
        group.add(new Text({
            x:0, y:-offset-fontSize-4,
            width:rwidth,
            text:rect.width,
            fill, fontSize, strokeWidth,
            align:'center',
        }));
        group.add(new Line({
            points: [3,-offset-3, -3,-offset+3],
            stroke, strokeWidth
        }));
        group.add(new Line({
            points: [rwidth+3, -offset-3, rwidth-3, -offset+3],
            stroke, strokeWidth
        }));

    }
}


function checkCross(rect,radio,lists){
    let bool = true;
    const cp = [0,0,0,0];
    const innerLeft = 0;
    const innerTop = 0;

    for(let i=0; i<lists.length; i++){
      const {width,height,top,left,status,title} = lists[i];
      let r = {
        left:left*radio+innerLeft+cp[3],
        top:top*radio+innerTop+cp[0],
        height:height*radio,
        width:width*radio
      }
      if(isCross(rect,r)){
        bool = false;
        break;
      }
    }

    // console.log(bool);
    return bool;
  }
  function isCross(rect1,rect2){
    // console.log(rect1,rect2);
    let lux = Math.max(rect1.left, rect2.left);
    let luy = Math.max(rect1.top, rect2.top);
    let rdx = Math.min(rect1.left+rect1.width, rect2.left+rect2.width);
    let rdy = Math.min(rect1.top+rect1.height, rect2.top+rect2.height);
    // return (lux<rdx && luy<rdy);
    //此处允许有1个像素误差，某些展位顶点有重合，不认为展位有重合
    // return (lux+1<rdx && luy+1<rdy); 
    return (lux+1<rdx && luy+1<rdy); 
  }