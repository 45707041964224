<template>
	<header>
  		<!-- <div class="left">
	        <img src="http://www.uniceramics.com.cn/image/logo2021.gif" alt="logo" />
	        <h2>潭洲展</h2>
	        <span>展位销售管理系统</span>
        </div> -->

        <div class="handle">
          <span>展馆：</span>
        	<a-form laba-width="60px">
	        	<a-form-item label="">
		        	<a-select style="width: 180px; font-size:12px" v-model:value="name" placeholder="请选择" @change="change" size="small">
					    <a-select-option
					      v-for="item in options"
					      :key="item.name"
					      :value="item.id">
                {{item.name}}
              </a-select-option>
					  </a-select>
				</a-form-item>
			</a-form>
        	<!-- <span class="name">{{rolename}}</span> -->
        </div>
        <span class="exit" @click="exitLogin()">退出登录</span>
        <div class="prev" @click="prev()"></div>
        <div class="next" @click="next()"></div>
    </header>
</template>

<script>
  import axios from 'axios';
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
  import { createVNode, defineComponent } from 'vue';
  import {getCategory} from '@/api/index'
  import { Modal } from 'ant-design-vue';

  export default {
    data() {
      return {
        options: [],
        name: '',
        rolename:'',
        id:'',
        index:0,
        category:[]
      };
    },
    created(){
      let token = localStorage.getItem('mtoken');
      // let category =  JSON.parse(localStorage.getItem('category'));
      let selectCategory =  JSON.parse(localStorage.getItem('selectCategory'));

      let user = JSON.parse(localStorage.getItem('user'));
      this.category=JSON.parse(localStorage.getItem('category'));

      this.rolename = user.name+'('+user.role_name+')';


      if(selectCategory!==null){
        this.name = selectCategory.name;
        this.id = selectCategory.id;
        this.$store.commit("setSelectCategory",selectCategory);
      }

      getCategory({'token':token}).then(res=>{
        // console.log(res);
        this.options = res;
        // console.log(this.name);

        // console.log(this.name==='');

        //初始化选中第一项
        if(this.name===''){
            console.log("初始化选中第一项");
            this.name = res[0].name;
            this.id = res[0].id;
            console.log(this.name);
            this.$store.commit("setCategory",res);
            this.$store.commit("setSelectCategory",res[0]);
            localStorage.setItem('category',JSON.stringify(res));
            localStorage.setItem('selectCategory',JSON.stringify(res[0]));
        }
        //加载展位数据
        this.$store.dispatch("getLists",this.id);
      })
      
    },
    methods: {
     	exitLogin(){
        
          Modal.confirm({
            title: '退出登录?',
            icon: createVNode(ExclamationCircleOutlined),
            content: "确认退出登录？",
            okText: '退出',
            cancelText:"取消",
            onOk:() => {
              localStorage.removeItem('mtoken');
              localStorage.removeItem('user');
              localStorage.removeItem('category');
              localStorage.removeItem('selectCategory');
              this.$router.push({ path: '/login' });
            }
          });
  		},
      change(id){
        console.log(id);
        let categoryData = this.options[this.getValue(id).idx];
        console.log(categoryData);
        console.log(categoryData.bottom_padding);

        // if(categoryData.bottom_padding===0&&categoryData.top_padding===0&&categoryData.left_padding===0&&categoryData.right_padding===0){
        //   console.log("内边距为0");
        //   categoryData.bottom_padding = 3000;
        //   categoryData.top_padding = 3000;
        //   categoryData.left_padding = 3000;
        //   categoryData.right_padding = 3000;
        //   categoryData.width = categoryData.width+6000
        //   categoryData.height = categoryData.height+6000
        //   categoryData.isNoPadding = true;
        // }

        localStorage.setItem('selectCategory',JSON.stringify(categoryData));
        localStorage.setItem('categoryIndex',this.getValue(id).idx);
        this.$store.commit("setSelectCategory",categoryData);
        this.$store.commit("setSelectIndex",this.getValue(id).idx);
        //切换展馆时候，清空所有临时展位
        this.$store.commit("setTempListsRemoveAll",[]);
        this.$emit('change',id);

        // window.location.reload();
      },
      next(){
        this.category=JSON.parse(localStorage.getItem('category'));
        let selectId = (localStorage.getItem('categoryIndex')||0)*1;
        let nextIdx = selectId+1
        if(nextIdx>this.category.length-1){
          nextIdx = 0
        }
        this.change(this.category[nextIdx].id);
        this.name = this.category[nextIdx].name;
      },
      prev(){
        this.category=JSON.parse(localStorage.getItem('category'));
        let selectId = (localStorage.getItem('categoryIndex')||0)*1;
        let nextIdx = selectId-1
        if(nextIdx===-1){
          nextIdx = 11
        }
        this.change(this.category[nextIdx].id);
        this.name = this.category[nextIdx].name;
      },
      getValue(id){
        // console.log(id);
        let name = '';
        let idx = '';
        this.options.forEach((res,index)=>{
          if(res.id == id){
            name = res.name;
            idx = index;
          }
        })
        return {name,idx};
      }
    }
  }
</script>

<style scoped>
header{position:fixed; top:0; left:0; right:0; display: flex; align-items: center; justify-content: space-between; height:40px;  border-bottom:1px solid #eee; box-shadow: 1px 1px 5px #ccc; padding:0 5px; z-index:8; background: #fff;}
header .left{display:flex; align-items: center; flex-direction: column; padding-left:0px;}
header .left>h2{ font-size:14px; color:#666; font-weight: bold;}
header .left>span{font-size:12px; position:relative; top:3px;}
header .left img{width:30px; height: 30px; position: absolute; left:5px; top:20px;}
header .handle{display: flex; align-items: center;}
header .handle span{margin-left:5px; color:#999;}
header span.exit{cursor: pointer; margin-right:5px;}
header span.exit:hover{color:#247fce;}
header .handle span.name{color:#247fce;}

header .ant-form-horizontal{margin-right:10px; width:190px; font-size:13px;}

header .ant-form-item{margin:0;}

/* .next{width:50px; height:50px; border-bottom:2px solid #d10b2f; border-right:2px solid #d10b2f; position:fixed; z-index:7; bottom:20px; left:50%; margin-left:-20px; transform: rotate(45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.next:hover{opacity:1;}
.prev{width:50px; height:50px; border-top:2px solid #d10b2f; border-left:2px solid #d10b2f; position:fixed; z-index:7; top:60px; left:50%; margin-left:-20px; transform: rotate(45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.prev:hover{opacity:1;} */

.next{width:30px; height:30px; border-top:2px solid #d10b2f; border-right:2px solid #d10b2f; position:fixed; z-index:7; right:10px; top:50%; margin-top:-20px; transform: rotate(45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.next:hover{opacity:1;}
.prev{width:30px; height:30px; border-top:2px solid #d10b2f; border-left:2px solid #d10b2f; position:fixed; z-index:7; left:10px; top:50%; margin-top:-20px; transform: rotate(-45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.prev:hover{opacity:1;}
</style>